exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-paginated-post-index-js": () => import("./../../../src/templates/paginatedPostIndex.js" /* webpackChunkName: "component---src-templates-paginated-post-index-js" */),
  "component---src-templates-paginated-post-index-tag-js": () => import("./../../../src/templates/paginatedPostIndex_tag.js" /* webpackChunkName: "component---src-templates-paginated-post-index-tag-js" */),
  "component---src-templates-paginated-post-index-year-js": () => import("./../../../src/templates/paginatedPostIndex_year.js" /* webpackChunkName: "component---src-templates-paginated-post-index-year-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-slide-show-js": () => import("./../../../src/templates/slide_show.js" /* webpackChunkName: "component---src-templates-slide-show-js" */)
}

